import React, { useState } from "react";
import HambugerIcon from "./help_components/HamburgerIcon";
import "./css_files/sidebar.css";

const Sidebar = ({isOpen,openHandler}) => {
  return (

    <div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <HambugerIcon script={openHandler} isOpen={isOpen}/>
            <ul className="nav_sections">
              <li>
                <div className="nav_lines_vert"/>
                <a href="/">Dashboard</a>
              </li>
              <li>
                <div className="nav_lines_vert"/>
                <a href="/userpage">User Menu</a>
              </li>
              <li>
                <div className="nav_lines_vert"/>
                <a href="#">Company Page</a>
              </li>
              <li>
                <div className="nav_lines_vert"/>
                <a href="/analytics">Analytics</a>
              </li>
            </ul>
      </div>
    </div>
  );
};

export default Sidebar;