import React from "react";


export default function HambugerIcon({script, isOpen}){
    return (
        <div className={'sidebar_icon'} onClick={script}>
                    <div className={`m_button_bar_1 ${isOpen ? 'open' : ''}`} id="bar_1"></div>
                    <div className={`m_button_bar_2 ${isOpen ? 'open' : ''}`} id="bar_2"></div>
                    <div className={`m_button_bar_3 ${isOpen ? 'open' : ''}`} id="bar_3"></div>
        </div>
    )
};
