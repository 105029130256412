import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { PieChart,pieArcLabelClasses } from "@mui/x-charts";
import PieCenterLabel from "./graph_helpers/PieCenterLabel";
import React, { useState, useEffect } from 'react';
import './css_files/graph_object.css'
import './css_files/acronis.css'
import { getColorByValue } from './help_components/colorHelper';

const Backups = ({tenant_name,isAdmin}) =>{
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/acronis').then(
            res => res.json()
        ).then(data => { set_graph_data(data) })
    }, []);
    const b_up = 95
    const nb_up = 5
    const all = 100

    let t_names = []
    graph_data.map(data => t_names.push(data.name))
    let backups = graph_data.map(data => 
        <div className='graphs' key={data.name}>
        <h4>Back-Ups</h4>
        <PieChart
            series={[{
                data: [
                    {
                        label: 'Backed Up',
                        value: b_up,
                        color: getColorByValue(b_up,all)
                    },

                    {
                        label: 'Not Backed Up',
                        value: nb_up,
                        color: '#979897'
                    },
                ],
                startAngle: -125,
                endAngle: 125,
                innerRadius: 90,
                cx: '65.5%',
                cy: '50%'
            }
            ]}
            sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                    fontFamily: 'Roboto'
                }
            }}
            slotProps={{
                popper: {
                    sx: {
                        ["& .MuiChartsTooltip-table"]: {
                            display: "none",
                        },
                    },
                },
                legend: {
                    padding: {top:50},
                    hidden: false,
                    direction: 'row',
                    position: {
                        vertical: 'bottom',
                        horizontal: 'middle',
                        
                    },
                    
                    labelStyle: {
                        fill: '#ffffff',
                        fontSize: 12
                    }

                }
            }}
            width={400}
            height={275}
        >
            <PieCenterLabel type="*">{[
                b_up,
                "Backed-Up",
                nb_up,
                "Not Backed-Up"
            ]}</PieCenterLabel>
        </PieChart>
    </div>
)
    if (t_names.includes(tenant_name)){
        backups.map(data => {
            if (data.key === tenant_name)
                backups = data
        })
    }
    return (
        <>
            <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
                {backups}
            </div>
        </>
    );
}

const BackupsLineGraph = ({tenant_name,isAdmin}) => {
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/acronis').then(
            res => res.json()
        ).then(data => { set_graph_data(data) })
    }, []);
    const labels = [
        "MS Group Mailboxes",
        "MS User Mailboxes",
        "Onedrive User Drives",
        "Google Shared Drives",
        "Google User Drives",
        "Google User Mailboxes"
    ]
    let t_names = []
    graph_data.map(data => t_names.push(data.name))
    let ac_section = graph_data.map(data => 
            <div className='graphs' key={data.name}>
            <h4>Backups</h4>
            <BarChart
                sx={({
                    [`.${axisClasses.root}`]: {
                        [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                            stroke: '#ffffff',
                            strokeWidth: 3,
                        },
                        [`.${axisClasses.tickLabel}`]: {
                            fill: '#ffffff',
                        },
                    },
                })}
                colors={["#0060ff", "#3380FF"]}//{["#0060ff", "#0060ff", "#3380FF", "#3380FF", "#169620"]} //#d1fbff #0060ff
                series={[
                    {data: [
                        data["MS Group Mailboxes"],
                        data["MS User Mailboxes"],
                        data['Onedrive User Drives'],
                        data['Google Shared Drives'],
                        data['Google User Drives'],
                        data['Google User Mailboxes']], 
                        label: 'Backed-Up', 
                        stack: 'backups', 
                        layout:'horizontal'},
                    { data: [0,0,0,0,0,0], label: 'Not Backed-Up', stack: 'backups', layout:'horizontal'},
                    
                ] 
                }
                
                yAxis={[
                    { data: labels, scaleType: 'band'}
                ]}
                slotProps={
                    {
                        legend: {
                            hidden: false,
                            labelStyle:
                            {
                                fontSize: 14,
                                fill: 'white',
                            },
                        },
                        label:{
                            fill: 'white',
                            color: 'white',
                        }

                    }}
                width={1200}
                height={275}
                margin={{left: 150}}
                
            />
            </div>
)
    if (t_names.includes(tenant_name)){
        ac_section.map(data => {
            if (data.key === tenant_name)
                ac_section = data
        })
    }
    return (
        <>
            <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
                {ac_section}
            </div>
        </>
    );
}

export {BackupsLineGraph, Backups}
// export default function AcronisGraph({ tenant_name, isAdmin }) {
//     const [graph_data, set_graph_data] = useState([{}])
//     useEffect(() => {
//         fetch('/acronis').then(
//             res => res.json()
//         ).then(data => { set_graph_data(data) })
//     }, []);
//     const labels = [
//         "MS Group Mailboxes",
//         "MS User Mailboxes",
//         "Onedrive User Drives",
//         "Google Shared Drives",
//         "Google User Drives",
//         "Google User Mailboxes"
//     ]
//     let g_width = 600
//     let g_height = 350
//     if (isAdmin) {
//         g_width = 400
//         g_height = 250
//     }
//     let t_names = []
//     graph_data.map(data => t_names.push(data.name))
//     let ac_section = graph_data.map(data => 
//         <div className='multi_graphs' key={data.name}>
//             <div className='graphs'>
//             <h4>Backups</h4>
//             <BarChart
//                 sx={({
//                     [`.${axisClasses.root}`]: {
//                         [`.${axisClasses.tick}, .${axisClasses.line}`]: {
//                             stroke: '#ffffff',
//                             strokeWidth: 3,
//                         },
//                         [`.${axisClasses.tickLabel}`]: {
//                             fill: '#ffffff',
//                         },
//                     },
//                 })}
//                 colors={["#0060ff", "#3380FF"]}//{["#0060ff", "#0060ff", "#3380FF", "#3380FF", "#169620"]} //#d1fbff #0060ff
//                 series={[
//                     {data: [
//                         data["MS Group Mailboxes"],
//                         data["MS User Mailboxes"],
//                         data['Onedrive User Drives'],
//                         data['Google Shared Drives'],
//                         data['Google User Drives'],
//                         data['Google User Mailboxes']], 
//                         label: 'Backed-Up', 
//                         stack: 'backups', 
//                         layout:'horizontal'},
//                     { data: [0,0,0,0,0,0], label: 'Not Backed-Up', stack: 'backups', layout:'horizontal'},
                    
//                 ] 
//                 }
                
//                 yAxis={[
//                     { data: labels, scaleType: 'band'}
//                 ]}
//                 slotProps={
//                     {
//                         legend: {
//                             hidden: false,
//                             labelStyle:
//                             {
//                                 fontSize: 14,
//                                 fill: 'white',
//                             },
//                         },
//                         label:{
//                             fill: 'white',
//                             color: 'white',
//                         }

//                     }}
//                 width={650}
//                 height={350}
//                 margin={{left: 150}}
                
//             />
//             </div>
//             <div className="graphs">
//                 <h4>Backed Up Computers</h4>
//                 <div className="user_count_value" key={data.name}>
//                     {data.computers}
//                 </div>
//             </div>
//         </div>
// )
//     if (t_names.includes(tenant_name)){
//         ac_section.map(data => {
//             if (data.key === tenant_name)
//                 ac_section = data
//         })
//     }
//     return (
//         <>
//             <h3>Back-Ups and Computers</h3>
//             <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
//                 {ac_section}
//             </div>
//         </>
//     );
// }

