import { BarChart } from '@mui/x-charts/BarChart';

import { axisClasses } from '@mui/x-charts/ChartsAxis';
import React, { useState, useEffect } from 'react';
import './css_files/graph_object.css'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import PieCenterLabel from './graph_helpers/PieCenterLabel'
import { getColorByValue } from './help_components/colorHelper';

const PassBarGraph = ({ tenant_name, isAdmin }) => {
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/keeper').then(
            res => res.json()
        ).then(data => { set_graph_data(data) })
    }, []);
    let t_names = []
    graph_data.map(data => t_names.push(data.name))
    let kp_section = graph_data.map(data =>
        <div className='graphs' key={data.name}>
            <h4>Overall Password Security</h4>
            <BarChart
                sx={({
                    [`.${axisClasses.root}`]: {
                        [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                            stroke: '#ffffff',
                            strokeWidth: 3,
                        },
                        [`.${axisClasses.tickLabel}`]: {
                            fill: '#ffffff',
                        },
                    },
                })}
                xAxis={[
                    {
                        id: 'barCategories',
                        data: ["Weak", "OK", "Strong", "Reused", "Unique"],
                        scaleType: 'band',
                        colorMap: {
                            type: 'ordinal',
                            colors: ['#ab0300', '#f4f400', '#00bd02', '#baa745', '#2245dd']
                        }
                    },
                ]}
                series={[
                    {
                        data: [data.weak, data.medium, data.strong, data.reused, data.unique],
                    },
                ]}
                width={600}
                height={250}
            />
        </div>
    );
    if (t_names.includes(tenant_name))
        kp_section.map(data => {
            if (data.key === tenant_name)
                kp_section = data
        }
        )
    return (
        <>
            <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
                {kp_section}
            </div>
        </>
    );
}

const PasswordSecurity = ({ tenant_name, isAdmin }) => {
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/keeper').then(
            res => res.json()
        ).then(data => { set_graph_data(data) })
    }, []);
    let t_names = []
    graph_data.map(data => 
        t_names.push(data.name),
    );
    let pie_section = graph_data.map(data =>
        <div className='graphs' key={data.name}>
            <h4>Password Security</h4>
            <PieChart
                series={[{
                    data: [
                        {
                            label: data.weak+' - Weak',
                            value: data.weak,
                            color: '#ff0300' //getColorByValue(data.strong, data.strong+data.medium+data.weak)
                        },
                        {
                            label: data.medium+' - Okay',
                            value: data.medium,
                            color: '#F5FF00'
                        },
                        {
                            label: data.strong + data.unique+' - Strong',
                            value: data.strong + data.unique,
                            color: '#00FF03'
                        },

                        {
                            label: data.reused+' - Reused',
                            value: data.reused,
                            color: '#979897'
                        },
                        // {
                        //     value: data.strong+data.medium+data.weak - data.strong,
                        //     color: '#a2a3a2'
                        // },
                    ],
                    startAngle: -125,
                    endAngle: 125,
                    innerRadius: 90,
                    cx: '65.5%',
                    cy: '50%'
                }
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fontWeight: 'bold',
                        fontFamily: 'Roboto'
                    }
                }}
                slotProps={{
                    popper: {
                        sx: {
                            ["& .MuiChartsTooltip-table"]: {
                                display: "none",
                            },
                        },
                    },
                    legend: {
                        padding: {left:50},
                        hidden: false,
                        direction: 'row',
                        position: {
                            vertical: 'bottom',
                            horizontal: 'middle',
                            
                        },
                        labelStyle: {
                            fill: '#ffffff',
                            fontSize: 12
                        },
                        itemMarkWidth:20,
                        
                    }
                }}
                width={400}
                height={275}
            >
                <PieCenterLabel type="**">{[
                    Math.round(((data.strong/(data.weak+data.medium+data.strong)*100)
                    +(data.unique/(data.weak+data.medium+data.strong)*100))/2)+'%',
                    "Security Score",
        
                    // data.reused,
                    // "Reused",
                    // data.unique,
                    // "Unique",
                ]}</PieCenterLabel>
            </PieChart>
        </div>
    );
    if (t_names.includes(tenant_name))
        pie_section.map(data => {
            if (data.key === tenant_name)
                pie_section = data
        }
        )
    return (
        <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
            {pie_section}
        </div>
    );
}
export { PasswordSecurity, PassBarGraph }

// export default function KeeperGraph({tenant_name,isAdmin}) {
//     const [graph_data, set_graph_data] = useState([{}])
//     useEffect(() => {
//         fetch('/keeper').then(
//             res => res.json()
//         ).then(data => { set_graph_data(data) })
//     }, []);
//     const PassBarGraph = () => {
//         let t_names = []
//         graph_data.map(data => t_names.push(data.name))
//         console.log(t_names)
//         let kp_section = graph_data.map(data =>
//                 <div className='graphs'>
//                         <h4>Overall Password Security</h4>
//                         <BarChart
//                             sx={({
//                                 [`.${axisClasses.root}`]: {
//                                     [`.${axisClasses.tick}, .${axisClasses.line}`]: {
//                                         stroke: '#ffffff',
//                                         strokeWidth: 3,
//                                     },
//                                     [`.${axisClasses.tickLabel}`]: {
//                                         fill: '#ffffff',
//                                     },
//                                 },
//                             })}
//                             xAxis={[
//                                 {
//                                     id: 'barCategories',
//                                     data: ["Weak", "OK", "Strong", "Reused", "Unique"],
//                                     scaleType: 'band',
//                                     colorMap: {
//                                         type: 'ordinal',
//                                         colors: ['#ab0300', '#f4f400', '#00bd02', '#baa745', '#2245dd']
//                                     }
//                                 },
//                             ]}
//                             series={[
//                                 {
//                                     data: [data.weak, data.medium, data.strong, data.reused, data.unique],
//                                 },
//                             ]}
//                             width={600}
//                             height={350}
//                         />
//             </div>
//         );
//         if (t_names.includes(tenant_name))
//             kp_section.map(data => {
//                 if(data.key === tenant_name)
//                     kp_section = data
//                 }
//             )
//         return (
//             <>
//                 <h3>Company Password Managment</h3>
//                 <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
//                     {kp_section}
//                 </div>
//             </>
//         );
//     }
//     const PPieGraph = () =>{
//         let t_names = []
//         graph_data.map(data => t_names.push(data.name))
//         console.log(t_names)
//         let pie_section = graph_data.map(data =>
//             <div className='graphs' key={data.name}>
//             <h4>Password Security</h4>
//                 <PieChart
//                     series={[{
//                         data: [
//                             {
//                                 value: data.security_score,
//                                 color: '#0042ff'
//                             },
//                             {
//                                 value: 100 - data.security_score,
//                                 color: '#a2a3a2'
//                             },
//                         ],
//                         startAngle: -125,
//                         endAngle: 125, //(sc_rating / total_sc) / 100 * 360
//                         innerRadius: 110,
//                         cx: '59.5%',
//                         cy: '50%'
//                     }
//                     ]}
//                     sx={{
//                         [`& .${pieArcLabelClasses.root}`]: {
//                             fontWeight: 'bold',
//                             fontFamily: 'Roboto'
//                         }
//                     }}
//                     slotProps={{
//                         popper: {
//                         sx: {
//                             ["& .MuiChartsTooltip-table"]: {
//                             display: "none",
//                             },
//                         },
//                         },
//                     }}
//                     width={600}
//                     height={350}
//                 >
//                     <PieCenterLabel type="%">{data.security_score}%</PieCenterLabel>
//                 </PieChart>
//             </div>
//         );
//         if (t_names.includes(tenant_name))
//             pie_section.map(data => {
//                 if(data.key === tenant_name)
//                     pie_section = data
//                 }
//             )
//         return (
//             <>
//                 <h3>Company Password Managment</h3>
//                 <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
//                     {pie_section}
//                 </div>
//             </>
//         );
//     }

// }

