import './css_files/usermenu.css'

export default function UsersMenu(){
    return(
        <section className='user_wrapper'>
            <h1 color="black">Current Users</h1>
            <article className='user_data'>
            </article>
        </section>
    )
}