import React, { useState, useEffect } from 'react'
import config from './auth/config'
import { AdminGraph } from './AdminGraph'

const AdminSection = () => {
    const [userData, setUserData] = useState([]);
    const [machineData, setMachineData] = useState([]);
    const [backupData, setBackUpData] = useState([]);
    const [cData, setCSecurityData] = useState([]);
    const [pData, setPSecurityData] = useState([]);
    const [nData, setNSecurityData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const [usrs_and_machines, backups, cSec, pSec, nSec] = await Promise.all([
                fetch('/dash_data/syncro'),
                fetch('/dash_data/acronis'),
                fetch('/dash_data/bitdefender'),
                fetch('/dash_data/keeper'),
                fetch('/dash_data/dnsfilter')
            ]);

            const userAndMachineData = await usrs_and_machines.json();
            const backupData = await backups.json();
            const computerSecData = await cSec.json();
            const passwordSecData = await pSec.json();
            const networkSecData = await nSec.json();
            setUserData(userAndMachineData)
            setMachineData(userAndMachineData)
            setBackUpData(backupData)
            setCSecurityData(computerSecData)
            setPSecurityData(passwordSecData)
            setNSecurityData(networkSecData)

        };
        fetchData();
    }, []);
    let all_data = [userData, machineData, backupData, cData, pData, nData]
    try {
        const tenants = config.TENANTS
        let graphs = tenants.map(data =>
            <AdminGraph
                tenant_name={data}
                data={all_data} />
        )
        return (
            
            <div className="adminGraphContainer">
                <div className='adminGraph'>
                    {graphs}
                </div>
                
            </div>
        )
    }
    catch (error) {
        console.log(error)
        return <p>An Error Occured</p>
    }

}
export { AdminSection }