import {React,useState, useEffect} from 'react'
import config from './auth/config';
import { Routes, Route, BrowserRouter } from "react-router-dom"
// import Button from "./auth/MyButton";
import UsersMenu from './UserMenu';
import SideBar from './SideBar';
import DB from './DB'
import './css_files/home_nav.css'
import Analytics from './Analytics';
export default function Home({ user, handleLogout }) {
     const emailRegex = /(?<=@)[^.]+(?=\.)/;
     let domain = config.DOMAIN_DICT[emailRegex.exec(user.email)]
     let isAdmin = false
     const admins = config.ADMINS
     const tenants = config.TENANTS
     const [isOpen, setSidebarOpen] = useState(false);
     
     if(admins.includes(user.email)){
          isAdmin = true
          domain = "Admin"
     }
     const handleSidebarOpen = () => {
          setSidebarOpen(!isOpen);
     }
     let admin_view_paths = <></>
     if (isAdmin){
          admin_view_paths = tenants.map(data =>
               <Route path={"/"+data} element={
                    <DB
                         testing_domain={data}
                         user={user}
                         state={isOpen}
                         admin_bool={false}
                         can_return={true}
                    />
               }/>   
          )
     }
     return (
          <div className='dash_background'>
               <section className={`header ${isOpen ? 'open' : ''}`}>
                    <div className={`log_out_section ${isOpen ? 'open' : ''}`}>
                         <SideBar isOpen={isOpen} openHandler={handleSidebarOpen}/>
                         <h2>Welcome {user.name}!</h2>
                         <button className='log_out_button' onClick={() => handleLogout()}>
                              Log-Out
                         </button>
                    </div>
               </section>
               <BrowserRouter>
                    <Routes>
                         <Route path="/" element={
                              <DB
                                   testing_domain={domain}
                                   user={user}
                                   state={isOpen}
                                   admin_bool={isAdmin}
                              />
                         } />
                         <Route path="/userpage" element={<UsersMenu />} />
                         <Route path="/analytics" element={<Analytics
                              testing_domain={domain}
                              user={user}
                              state={isOpen}
                              admin_bool={isAdmin}
                              />
                         } />
                         {admin_view_paths}
                    </Routes>
               </BrowserRouter>
          </div>
     )
}
