import React, { useState } from 'react';
import config from"./config"
import "./auth.css"
function MyForm({loginFunction, msLoginFunction}){
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const handleEmailChange = (event) => {
    event.preventDefault();
    const emailRegex = /(?<=@)[^.]+(?=\.)/;
    const email_domain = config.TENANT_DICT[emailRegex.exec(email)]
    // setValidEmail(true)
    if (email_domain === "google"){
        loginFunction();
        setValidEmail(true)
    }
    else if (email_domain === "microsoft"){
        msLoginFunction();
        setValidEmail(true)
    }
    else{
        console.log("Email Domain Not Found!");
        setValidEmail(false)
    }
  };
  return (
    <form className="login_form" onSubmit={handleEmailChange}> 
      <input className="email_text" type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
      <input className="login_button" type="submit" value={"Log in"}/>
      {validEmail ? "" : <span className="warning_text">Please enter a valid email address</span>}
    </form>
  );
};
export default MyForm;