import './css_files/admin_dash.css'
import lethos_logo from './Lethos-Logo.png'
import astral_logo from './Astral-Logo.svg'
import ebl_logo from './EBL-Logo-2.png'
import { getColorByValue } from './help_components/colorHelper'
const AdminGraph = ({ tenant_name, data }) => {
    let t_data = data
        .flat()
        .filter((obj) => obj.name === tenant_name);
    const data_array = (t_data || []).reduce((acc, item, index) => {
        acc[index] = { ...item };
        return acc
    }, {})

    if (data_array[0]) {
        const active_users = data_array[0].active
        const inactive = data_array[0].inactive
        const user_count = data_array[0].total - data_array[0].inactive
        const mail = data_array[0].mailboxes
        const all_users = data_array[0].total
        const machines = data_array[0].computers
        const backups_protected = 45
        const total_backups = 100
        const percent_of_users = Math.round(active_users / all_users * 100)
        const percent_of_mailboxes = Math.round(user_count / all_users * 100)
        const machines_percentage = Math.round(data_array[0].computers)
        const total_backups_protected = Math.round(45)
        const computer_security_score = Math.round(100)
        const password_security_score = Math.round(data_array[3].security_score)
        const network_security_score = Math.round(10 / 13 * 100)
        let logo = ""
        if (tenant_name === "Lethos"){
            logo = lethos_logo
        }
        else if (tenant_name === "Astral Insights"){
            logo = astral_logo
        }
        else{
            logo = ebl_logo
        }
        let admin_graph =
            <>
                <div className="logo_border">
                    <a href={tenant_name}>
                        <img className='t_logo'src={logo}></img>
                    </a>
                </div>
                <div className='barContainer'>
                    <h4>
                        Users - {active_users} / {all_users} | Mailboxes - {mail}</h4>
                    <div className='doublebar' style={{
                        width: percent_of_mailboxes+'%',
                        backgroundColor: '#0c82f3'}}>
                            <div className='doublebar_2' style={{
                        width: percent_of_users+'%',
                        backgroundColor: 'blue'}}>
                            </div> 
                        </div> 
                    <h4>Machines - {machines} / {active_users}</h4>
                    <div className='bar' style={{width: machines_percentage+'%',
                        backgroundColor: 'blue'}}></div> 
                    <h4>Back-Ups - {backups_protected} / {total_backups}</h4>
                    <div className='bar' style={{width: total_backups_protected+'%',
                        backgroundColor: getColorByValue(total_backups_protected,100)}}></div> 
                    <h4>Endpoint Security - {computer_security_score}%</h4>
                    <div className='bar' style={{width: computer_security_score+'%',
                        backgroundColor: getColorByValue(computer_security_score, 100)}}></div> 
                    <h4>Password Security - {password_security_score}%</h4>
                    <div className='bar' style={{width: password_security_score+'%',
                        backgroundColor: getColorByValue(password_security_score, 100)}}></div> 
                    <h4>Network Security - {network_security_score}%</h4>
                    <div className='bar' style={{width: network_security_score+'%',
                        backgroundColor: getColorByValue(network_security_score, 100)}}></div> 
                    <a href={tenant_name}className='redirect_button'>More Info</a>
                </div> 
            </>
        return admin_graph
    }
    else {
        return <p>Loading...</p>
    }
}
export { AdminGraph }