import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts';
import PieCenterLabel from './graph_helpers/PieCenterLabel';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import React, { useState, useEffect } from 'react';
import './css_files/graph_object.css'
import {getColorByValue} from './help_components/colorHelper'

const MachineSecurity = ({tenant_name, isAdmin}) =>{
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/bitdefender').then(
            res => res.json()
        ).then(data => { set_graph_data(data) })
    }, []);
    let t_names = []
    graph_data.map(data => t_names.push(data.name))
    let bd_pie = graph_data.map(data => 
    <div className='graphs' key={data.name}>
        <h4>Computer Security</h4>
        <PieChart
            margin={{}}
            series={[
                {
                data: [
                    { id: 0, value: data.security_score,color: getColorByValue(data.security_score, 100)},
                    { id: 1, value: 100 - data.security_score, color: '#a2a3a2'}
                ],
                startAngle: -125,
                endAngle: 125,
                innerRadius: 90,
                cx: '65.5%',
                cy: '50%'
                },
            ]}
            width={400}
            height={275}
        >
        <PieCenterLabel type="%">{data.security_score}%</PieCenterLabel>
        </PieChart>

    </div>
    )
    if (t_names.includes(tenant_name))
        bd_pie.map(data => {
            if(data.key === tenant_name)
                bd_pie = data  
            }
        )
    return (
            <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
            {bd_pie}
            </div>
    );
}
const MachineSecurityBar = ({tenant_name, isAdmin}) =>{
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/bitdefender').then(
            res => res.json()
        ).then(data => { set_graph_data(data) })
    }, []);

    let t_names = []
    let g_width = 600
    let g_height = 250
    graph_data.map(data => t_names.push(data.name))
    let bd_section = graph_data.map(data => 
    <div className='graphs' key={data.name}>
        <h4>Company Device Risk Data</h4>
        <BarChart
            sx={({
                [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                        stroke: '#ffffff',
                        strokeWidth: 3,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                        fill: '#ffffff',
                    },
                },
            })}
            //colors={colors}
            series={[
                {
                    data: [data.ORS, data.CM, data.AVIC, data.HR, data.IM]
                },
            ]}
            xAxis={[
                {
                    id: 'barCategories',
                    data: ["Company Risk", "Misconfigs", "App Risks", "Human Risks", "Industry Mod"],
                    scaleType: 'band',
                    colorMap: {
                        type: 'ordinal',
                        colors: ['#ff0000', '#FF8000', '#FF0080', '#FFC200', '#BDFF00', '#A25D62']
                    }
                },
            ]}

            width={g_width}
            height={g_height}
        />
    </div>
    )
    if (t_names.includes(tenant_name))
        bd_section.map(data => {
            if(data.key === tenant_name)
                bd_section = data  
            }
        )
    return (
        <>
            <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
            {bd_section}
            </div>
        </>
    );
}

export {MachineSecurityBar,MachineSecurity}