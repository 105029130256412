//import "./css_files/App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Auth from "./auth/auth";
import config from"./auth/config"
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalConfig = {
  auth: {
    clientId: config.HOKEY, // This is from the Azure portal
    authority: config.AUTHORITYS, // Microsoft Entra directory (tenant) ID
    redirectUri: 'http://localhost:5000/getAToken', // This is your React frontend's address
  }
};

const pca = new PublicClientApplication(msalConfig);

function App() {

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={config.BREKBURRITO}>
        <MsalProvider instance={pca}>
          <div className="bg_image">
              <div className="authpage">
                <Auth/> 
              </div>
          </div>
        </MsalProvider>
      </GoogleOAuthProvider>
    </div>
  );
}
export default App;