// export const getColorByValue = (value,max_val) => {
//     // Convert value to an integer
//     let int_value = parseInt(value, 10);
//     let max_value = parseInt(max_val, 10);
//     // Check if the conversion was successful
//     if (isNaN(int_value)) return 'gray'; // Return default color for invalid input
//     console.log(int_value/max_val * 100)
//     let final = (int_value/max_val * 100)
//     //console.log(final)
//     // Determine the color based on the integer value
//     if (final >= 0 && final <= 33) return 'red';
//     if (final > 33 && final <= 66) return 'yellow';
//     if (final > 66 && final <= 100) return 'green';
    
//     return 'gray'; // Default color if the value is outside the range
//   };
// colorHelper.js
export const getColorByValue = (value, max_val) => {
    const intValue = parseInt(value, 10);
    const maxVal = parseInt(max_val, 10)
    if (isNaN(intValue) || intValue < 0) return 'rgb(128, 128, 128)'; // Return gray for invalid input
    const final_val = (intValue/maxVal * 100)
    // Calculate the RGB values
    let r = 255, g = 0, b = 0;
    if (final_val <= 50 ) {
      return '#ff0000' //red 
    }
    else if (final_val > 50 && final_val <= 60){
      return '#f04A00'
    }
    else if (final_val > 60 && final_val <= 70){
      return '#ff9500'
    }
    else if (final_val > 70 && final_val <= 80){
      return '#fffa00'
    }
    else if (final_val > 80 && final_val <= 90){
      return '#88ee11'
    }
    else if (final_val > 90 && final_val <= 100){
      return '#2dff00'
    }
    // const normalizedValue = (intValue - 50) / 50;
    // r = Math.round(255 * (1 - normalizedValue * .25)); // Reduce red less aggressively
    // g = Math.round(128 * normalizedValue * 2.5);     
    // b = 0;                                 
    // }
  
    // // Ensure all values are clamped between 0 and 255 to maintain RGB consistency
    // r = Math.min(255, Math.max(0, r));
    // g = Math.min(255, Math.max(0, g));
    // b = Math.min(255, Math.max(0, b));
  
    // Return the color as an RGB string
    // return `rgb(${r}, ${g}, ${b})`;
  }