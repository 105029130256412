import React, { useState, useEffect} from "react";
import AuthForm from "./AuthForm"
import lethos_logo from './lethos_full.png'
import { useGoogleLogin } from "@react-oauth/google";
import Home from "../Home";
import config from"./config"

async function logout(){
  return fetch('/dash_data/logout')
}
async function getUserInfo(codeResponse) {
  var response = await fetch('/dash_data/google_login', {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code: codeResponse.code }),
  });
  console.log("Google User Info Grabbed")
  const data = await response.json()
  console.log(data)
  return data;
}

async function getMsUser(codeResponse) {
  const response = await fetch("/dash_data/ms_login",{
    method: "POST",
    mode: 'cors',
    headers:{
      "Content-Type" : "application/json",
    },
    body: JSON.stringify(codeResponse),
  });
  console.log("Microsoft User Info Grabbed")
  return await response.json();
}

export default function Auth() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  console.log("**Auth Function Hit**")
  const newLogin = async () =>{
    const loginRequest = {
      scopes: ["Users.Read"],
    };

    try{
      console.log("**MS Login hit**")
      await config.MSLAINSANTCE.initialize();
      await config.MSLAINSANTCE.handleRedirectPromise();
      const loginResponse = await config.MSLAINSANTCE.loginPopup();
      const account = loginResponse.account;
      const user = await getMsUser(account)
      console.log("[new_login] User: ",user)
      if (user.email && user.email_verified === true){
        console.log("Microsoft User is now Logged in")
        localStorage.setItem('loggedIn', true);
        localStorage.setItem('user', JSON.stringify(user));
        setLoggedIn(true);
        setUser(user);
      }
      else{
        handleLogout()
        console.log("Microsoft User is Logged out")
      }
      
    } catch (error){
      console.log("Login Failed", error)
    }
  }

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      var user = await getUserInfo(codeResponse);
      if (user.email && user.email_verified === true) {
        console.log("Google User is now Logged in")
        localStorage.setItem('loggedIn', true);
        localStorage.setItem('user', JSON.stringify(user));
        setLoggedIn(true);
        setUser(user);
      }
      else {
        console.log("Google User is now Logged out")
        handleLogout()
      }
    
    },
    onError : async (codeResponse) => {
      console.log(codeResponse, " Error has occured")
    }
  });

  const handleLogout = () => {
    console.log("**handleLogout function hit**")
    logout()
    setLoggedIn(false)
    setUser({})
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("user");
  };
  useEffect(() => {
    const loggedInCheck = localStorage.getItem('loggedIn');
    const storedUser = localStorage.getItem('user');
    console.log("Auth User: ",storedUser)
    if (storedUser === ('undefined' || {})) {
      console.log("User is logged OUT")
      handleLogout()
    }
    else if (loggedInCheck === "true" && storedUser !== 'None') { //NaN
      console.log("User is logged in")
      setUser(JSON.parse(storedUser))
      setLoggedIn(true)
    }
  }, []);
  return (
    <>
      {!loggedIn ? (
        <>
        <div className='login_background'/>
        <div className='login_flex_box'>
            <img src={lethos_logo} alt="Lethos Main Logo" />
            <AuthForm loginFunction = {googleLogin}
                      msLoginFunction = {newLogin}/>
        </div>
        </>
      ) : (
        <div className='dashboard_background'>
          <Home user={user} handleLogout={handleLogout} />
        </div>
      )}
    </>
  );
}