
import './css_files/graph_container.css'
import './css_files/analytics.css'
import { MachineSecurityBar } from './BitdefenderSection'
import { BackupsLineGraph } from './AcronisSection'
import { PassBarGraph } from './KeeperSection'

export default function Analytics({ testing_domain, user, state, admin_bool }) {
    return (
        <div className={`analytics ${state ? 'open' : ''}`}>
            <section className='ana_content'>
                <span>
                    <MachineSecurityBar 
                        tenant_name={testing_domain}
                        isAdmin={admin_bool}/>
                </span>
                <span>
                    <PassBarGraph
                        tenant_name={testing_domain}
                        isAdmin={admin_bool}/>
                </span>
                <span className='backup_graph'>
                    <BackupsLineGraph
                        tenant_name={testing_domain}
                        isAdmin={admin_bool}/>
                </span>
            </section>
        </div>
    )
}