import { PublicClientApplication } from "@azure/msal-browser";
const msalConfig = {
  auth: {
    clientId: "bfa3672b-b3e4-44c1-a6b9-bd7c73eac1e8",
    authority: "https://login.microsoftonline.com/fb2a2285-f040-405a-ac2d-403e42b383e8",
    redirectUri: "http://localhost:3000/",
  },
};

export default {
    BREKBURRITO : "557227395769-thiupikqsj0mtkoiggqquogf6c8agflp.apps.googleusercontent.com",
    HOKEY : "bfa3672b-b3e4-44c1-a6b9-bd7c73eac1e8",
    AUTHORITYS : "https://login.microsoftonline.com/fb2a2285-f040-405a-ac2d-403e42b383e8",
    TENANT_DICT : {"as" : "microsoft",
                   "astralinsights" : "microsoft",
                   "lethos" : "google",
                   "gmail" : "google",
                   "edgebrooklane" : "microsoft"
    },
    DOMAIN_DICT : { "lethos" : "Lethos",
        "astralinsights" : "Astral Insights",
        //"lethos" : "Lethos",
        "gmail" : "EdgeBrook Lane",
        "edgebrooklane" : "EdgeBrook Lane"
    },
    ADMINS: ["djkaras4u@gmail.com", "ed@lethos.com", "nate@lethos.com"],
    MSLAINSANTCE : new PublicClientApplication(msalConfig),
    TENANTS : ["Lethos", "Astral Insights", "EdgeBrook Lane"]
};