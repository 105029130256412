import React, { useState, useEffect } from 'react'
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import PieCenterLabel from './graph_helpers/PieCenterLabel'
import './css_files/graph_object.css'
import { getColorByValue } from './help_components/colorHelper';

const Users = ({tenant_name,user,isAdmin}) =>{
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/syncro').then(
            res => res.json()
        ).then(data => { set_graph_data(data)})
    }, []);
    let t_names = []
    // let g_width = 400
    // let g_height = 275
    // if (isAdmin) {
    //     g_width = 400
    //     g_height = 250
    // }
    graph_data.map(data => t_names.push(data.name))
    let syncro_section = graph_data.map(data =>
            <div className='graphs' key={data.name}>
                <h4>Users</h4>
                <PieChart
                    margin={{
                    
                    }}
                    
                    series={[{
                        data: [
                            {
                                label: 'Active',
                                value: data.active / data.total,
                                color: data.color
                            },
                            {
                                label: 'Mailboxes',
                                value: data.mailboxes / data.total,
                                color: data.alt_color
                            },
                            {
                                label: 'Inactive',
                                value: data.inactive / data.total,
                                color: '#979897'
                            },
                        ],
                        startAngle: -125,
                        endAngle: 125,
                        innerRadius: 80,
                        outerRadius: 130,
                        cx: '65.5%',
                        cy: '50%'
                    }
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fontWeight: 'bold',
                            fontFamily: 'Roboto',
                            
                        }
                    }}
                    slotProps={{
                        popper: {
                            sx: {
                                ["& .MuiChartsTooltip-table"]: {
                                    display: "none",
                                },
                            },
                        },
                        legend: {
                            padding: {top:50},
                            hidden: false,
                            direction: 'row',
                            position: {
                                vertical: 'bottom',
                                horizontal: 'middle',
                                
                            },
                            labelStyle: {
                                fill: '#ffffff',
                                fontSize: 12
                            }
                        }
                    }}
                    width={400}
                    height={275}
                >
                    <PieCenterLabel type="*">{[
                        data.active,
                        "Active Members",
                        data.mailboxes,
                        "Mail Boxes"
                    ]}</PieCenterLabel>
                </PieChart>
            </div>
    );
    if (t_names.includes(tenant_name))
        syncro_section.map(data => {
            if (data.key === tenant_name)
                syncro_section = data
        }
        )
    return (
        <>
            <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
                {syncro_section}
            </div>
        </>

    );
}

const Machines = ({tenant_name, user, isAdmin}) =>{
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/syncro').then(
            res => res.json()
        ).then(data => { set_graph_data(data) })
    }, []);
    
    let t_names = []
    graph_data.map(data => t_names.push(data.name))
    let machine_count = graph_data.map(data =>
        <div className='graphs' key={data.name}>
        <h4>Machines</h4>
        <PieChart
            series={[{
                data: [
                    {
                        label: 'Registerd Devices',
                        value: data.computers,
                        color: getColorByValue(data.computers, data.active)
                    },

                    {
                        label: 'Active Users',
                        value: data.active-data.computers,
                        color: '#979897'
                    },
                ],
                startAngle: -125,
                endAngle: 125,
                innerRadius: 90,
                cx: '65.5%',
                cy: '50%'
            }
            ]}
            sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                    fontFamily: 'Roboto'
                }
            }}
            slotProps={{
                popper: {
                    sx: {
                        ["& .MuiChartsTooltip-table"]: {
                            display: "none",
                        },
                    },
                },
                legend: {
                    padding: {top:50},
                    hidden: false,
                    direction: 'row',
                    position: {
                        vertical: 'bottom',
                        horizontal: 'middle',
                        
                    },
                    
                    labelStyle: {
                        fill: '#ffffff',
                        fontSize: 12
                    }

                }
            }}
            width={400}
            height={275}
        >
            <PieCenterLabel type="*">{[
                data.computers,
                "Registerd Machines",
                data.active,
                "Registerd Users"
            ]}</PieCenterLabel>
        </PieChart>
    </div>
    );
    if (t_names.includes(tenant_name))
        machine_count.map(data => {
            if (data.key === tenant_name)
                machine_count = data
        }
        )
    return (
        <>
            <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
                {machine_count}
            </div>
        </>

    );
}
export {Users, Machines}
// export default function SyncroGraph({ tenant_name, user, isAdmin }) {

//     const [graph_data, set_graph_data] = useState([{}])
//     useEffect(() => {
//         fetch('/syncro').then(
//             res => res.json()
//         ).then(data => { set_graph_data(data) })
//     }, []);
//     let t_names = []
//     let g_width = 500
//     let g_height = 300
//     if (isAdmin) {
//         g_width = 400
//         g_height = 250
//     }
//     graph_data.map(data => t_names.push(data.name))
//     let syncro_section = graph_data.map(data =>
//         <div className='multi_graphs' key={data.name}>
//             <div className='graphs' key={data.name}>
//                 <h4>{data.name} Syncro Users</h4>
//                 <PieChart
//                     series={[{
//                         data: [
//                             {
//                                 label: 'Active',
//                                 value: data.active / data.total,
//                                 color: data.color
//                             },
//                             {
//                                 label: 'Mailboxes',
//                                 value: data.mailboxes / data.total,
//                                 color: data.alt_color
//                             },
//                             {
//                                 label: 'Inactive',
//                                 value: data.inactive / data.total,
//                                 color: '#979897'
//                             },
//                         ],
//                         startAngle: -125,
//                         endAngle: 125, //(sc_rating / total_sc) / 100 * 360
//                         innerRadius: 100,
//                         cx: '59.5%',
//                         cy: '50%'
//                     }
//                     ]}
//                     sx={{
//                         [`& .${pieArcLabelClasses.root}`]: {
//                             fontWeight: 'bold',
//                             fontFamily: 'Roboto'
//                         }
//                     }}
//                     slotProps={{
//                         popper: {
//                             sx: {
//                                 ["& .MuiChartsTooltip-table"]: {
//                                     display: "none",
//                                 },
//                             },
//                         },
//                         legend: {
//                             hidden: false,
//                             direction: 'column',
//                             position: {
//                                 vertical: 'top',
//                                 horizontal: 'right',
//                             },
//                             padding: '20px',
//                             labelStyle: {
//                                 fill: '#ffffff'
//                             }

//                         }
//                     }}
//                     width={500}
//                     height={300}
//                 >
//                     <PieCenterLabel type="*">{[
//                         data.active,
//                         "Active Members",
//                         data.mailboxes,
//                         "Mail Boxes"
//                     ]}</PieCenterLabel>
//                 </PieChart>
//             </div>
//             <div className="graphs">
//                 <h4>Devices</h4>
//                 <div className="user_count_value" key={data.name}>
//                     {data.computers}
//                 </div>
//             </div>
//         </div>
//     );
//     if (t_names.includes(tenant_name))
//         syncro_section.map(data => {
//             if (data.key === tenant_name)
//                 syncro_section = data
//         }
//         )
//     return (
//         <>
//             <h3>Company Members</h3>
//             <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
//                 {syncro_section}
//             </div>
//         </>

//     );
// }

// speedometer =
//                 <div className='graph'>
//                     <CircularProgressbarWithChildren
//                         circleRatio={"." + percentage}
//                         strokeWidth={15}
//                         value={((tenant.mailboxes + tenant.active) / tenant.total) * 100}
//                         initialAnimation
//                         styles={buildStyles({
//                             rotation: .5 + (1 - percentage / 100) / 2,
//                             pathColor: tenant.alt_color,
//                             trailColor: "#5e6676",
//                             strokeLinecap: "butt"
//                         })}>
//                         <div className="inner_bar">
//                             <CircularProgressbar
//                                 value={(tenant.active / tenant.total) * 100}
//                                 circleRatio={"." + percentage}
//                                 strokeWidth={15}
//                                 initialAnimation
//                                 styles={buildStyles({
//                                     trailColor: "transparent",
//                                     strokeLinecap: "butt",
//                                     pathColor: tenant.color,
//                                     rotation: .5 + (1 - percentage / 100) / 2

//                                 })} />
//                         </div>
//                         <section className="user_container">
//                             <div className="user_content">
//                                 <strong className="primary_num">{tenant.active}</strong>
//                                 <strong className="sub_text">Active Members</strong>
//                             </div>
//                             <div className="user_content">
//                                 <strong className="tenants_2">{tenant.mailboxes}</strong>
//                                 <strong className="sub_text_2">Mail Boxes</strong>
//                             </div>
//                         </section>
//                     </CircularProgressbarWithChildren>
//                 </div>