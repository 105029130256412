import { React, useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts";
import PieCenterLabel from "./graph_helpers/PieCenterLabel";
import './css_files/graph_object.css'
import { getColorByValue } from './help_components/colorHelper';

const Network = ({ tenant_name, isAdmin }) => {
    const [graph_data, set_graph_data] = useState([{}])
    useEffect(() => {
        fetch('/dash_data/dnsfilter').then(
            res => res.json()
        ).then(data => { set_graph_data(data) })
    }, []);
    let t_names = []
    graph_data.map(data => t_names.push(data.name))
    let security_score = graph_data.map(data =>

        <div className='graphs' key={data.company_name}>
            <h4>Network Security</h4>
            <PieChart
                series={[
                    {
                        data: [
                            { id: 0, label: "Protected",value: 10, color: getColorByValue(10,13)},
                            { id: 1, label: "Un-Protected",value: 3, color: '#a2a3a2' }
                        ],
                        startAngle: -125,
                        endAngle: 125,
                        innerRadius: 90,
                        cx: '65.5%',
                        cy: '50%'
                    },
                ]}
                width={400}
                height={275}
                slotProps={{
                    popper: {
                        sx: {
                            ["& .MuiChartsTooltip-table"]: {
                                display: "none",
                            },
                        },
                    },
                    legend: {
                        padding: {left:50},
                        hidden: false,
                        direction: 'row',
                        position: {
                            vertical: 'bottom',
                            horizontal: 'middle',
                            
                        },
                        labelStyle: {
                            fill: '#ffffff',
                            fontSize: 12
                        },
                        itemMarkWidth:20,
                        
                    }
                }}
            >
                <PieCenterLabel type="*">{[
                        10,
                        "Devices Protected",
                        13,
                        "Total Devices"
                    ]}</PieCenterLabel>
            </PieChart>

        </div>
    )
    if (t_names.includes(tenant_name)) {
        security_score.map(data => {
            if (security_score.key === tenant_name)
                security_score = data
        })
    }
    return (
        <div className={`graph_section ${isAdmin ? 'admin' : ''}`}>
            {security_score}
        </div>
    );
}

export { Network };