import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const StyledText = styled('text')(({ theme }) => ({
  fill: 'white',
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontFamily: 'Roboto',
}));

export default function PieCenterLabel({ type, children }) {
  const { width, height, left, top } = useDrawingArea();
  if (type === '%') {
    return (
      <StyledText x={left + width / 1.5} y={top + height / 2.1} fontSize={52} fontWeight={800}>
        {children}
      </StyledText>
    );
  }
  else if (type === '*'){
    const x_pos = 1.5
    return (
      <>
        <StyledText x={left + width / x_pos} y={top + height / 2.5} fontSize={52} fontWeight={800}>
          {children[0]}
        </StyledText>
        <StyledText x={left + width / x_pos} y={top + height / 1.8} fontSize={15} fontWeight={600}>
          {children[1]}
        </StyledText>
        <StyledText x={left + width / x_pos} y={top + height / 1.4} fontSize={30} fontWeight={800}>
          {children[2]}
        </StyledText>
        <StyledText x={left + width / x_pos} y={top + height / 1.222} fontSize={12} fontWeight={600}>
          {children[3]}
        </StyledText>
      </>
    );
  }
  else if (type === '**'){
    const x_pos = 1.5
    const sideval_1_x = 1.25
    const sideval_2_x = 2
    const bottomval1_y = 1.1
    const bottomval2_y = 1
    return (
      <>
        <StyledText x={left + width / x_pos} y={top + height / 2.5} fontSize={52} fontWeight={800}>
          {children[0]}
        </StyledText>
        <StyledText x={left + width / x_pos} y={top + height / 1.8} fontSize={15} fontWeight={600}>
          {children[1]}
        </StyledText>
        <StyledText x={left + width / sideval_1_x} y={top + height / 1.4} fontSize={30} fontWeight={800}>
          {children[2]}
        </StyledText>
        <StyledText x={left + width / sideval_1_x} y={top + height / 1.222} fontSize={12} fontWeight={600}>
          {children[3]}
        </StyledText>
        <StyledText x={left + width / sideval_2_x} y={top + height / 1.4} fontSize={30} fontWeight={800}>
          {children[4]}
        </StyledText>
        <StyledText x={left + width / sideval_2_x} y={top + height / 1.222} fontSize={12} fontWeight={600}>
          {children[5]}
        </StyledText>
        <StyledText x={left + width / sideval_1_x} y={top + height / bottomval1_y} fontSize={30} fontWeight={800}>
          {children[6]}
        </StyledText>
        <StyledText x={left + width / sideval_1_x} y={top + height / bottomval2_y} fontSize={12} fontWeight={600}>
          {children[7]}
        </StyledText>
        <StyledText x={left + width / sideval_2_x} y={top + height / bottomval1_y} fontSize={30} fontWeight={800}>
          {children[8]}
        </StyledText>
        <StyledText x={left + width / sideval_2_x} y={top + height / bottomval2_y} fontSize={12} fontWeight={600}>
          {children[9]}
        </StyledText>
      </>
    );
  }
}