import { Users, Machines } from './SyncroSection';
import { MachineSecurity } from './BitdefenderSection';
import { PasswordSecurity } from './KeeperSection'
import { Network } from './Network';
import { Backups } from './AcronisSection';
import { AdminSection } from './AdminSection';
import './css_files/graph_container.css'
import './css_files/dashboard.css'
export default function DB({ testing_domain, user, state, admin_bool, can_return }) {
    let dashboard = <p>There was a problem loading the page</p>
    let back_button = <></>
    if (can_return){
        back_button =
        <a href='/' className='back_button'>Return to Console</a>
    }
    if (!admin_bool) {
        dashboard =
        <div className='dash_container'> 
        {back_button}
            <section className='dash_grid'>
                <span>
                    <Users
                        tenant_name={testing_domain}
                        isAdmin={admin_bool} />
                </span>
                <span>
                    <Machines
                        tenant_name={testing_domain}
                        isAdmin={admin_bool} />
                </span>
                <span>
                    <Backups
                        tenant_name={testing_domain}
                        isAdmin={admin_bool} />
                </span>
                <span>
                    <MachineSecurity
                        tenant_name={testing_domain}
                        isAdmin={admin_bool} />
                </span>
                <span>
                    <PasswordSecurity
                        tenant_name={testing_domain}
                        isAdmin={admin_bool} />
                </span>
                <span>
                    <Network
                        tenant_name={testing_domain}
                        isAdmin={admin_bool}
                    />
                </span>
            </section>
        </div>
    } else {
        dashboard =
        <section className='admin_dash'>
            <AdminSection/>
        </section>   
    }
    return (
        <div className={`dash ${state ? 'open' : ''}`}>
            {dashboard}
        </div>
    )
}